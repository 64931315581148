
























































import {Component, Prop, Vue} from 'vue-property-decorator';
import NorthStarService from "@/NorthStarService";
import {getDaysInMonth, addDays, setDate} from "date-fns";


@Component({
  components: {},
})
export default class AddGanttItem extends Vue {
  service: NorthStarService | null = null;

  nsName: string | null = null;
  selectedColor: string | null = null;
  nsDueDate: any = null;
  nsStartDate: any = null;

  automaticAdding: boolean = false;
  currentlyAddingItem: boolean = false;
  readyToEdit: boolean = false;

  xPosition: any = 0;
  laneWidth: any = 0;

  playPulseAnimation: boolean = false;

  @Prop({ required: true })
  gridCells!: any | null;

  @Prop({ required: true })
  index!: any | null;

  @Prop({ required: true, default: '186' })
  gridScale!: any | null;

  @Prop({ required: false, default: '6' })
  gridScaleGranularity!: any | null;

  colors: Array<string> = [
    '#316863',
    '#4A677D',
    '#4E6068',
    '#7B7343',
    '#654C4F',
    '#5B507A',
    '#66737a',
    '#546356',
    '#1F4730',
    '#642B2C',
    '#2E294E',
    '#820263',
    '#64024C',
    '#773F75',
  ];

  preSetColors: Array<string> = [
    '#773F75',
    '#4A677D',
    '#4E6068',
    '#5B507A',
    '#7B7343',
    '#654C4F',
    '#316863',
  ];

  get keymap() {
    return {
      // 'esc+ctrl' is OK.  - OBSERVE: don't add parenthesis at the end of the function triggered.. This messes things up.
      'meta+k': this.animateAddItem,
    }
  }

  animateAddItem() {
    if(this.index === 0) {
      this.currentlyAddingItem = true;
      this.automaticAdding = true;
      this.xPosition = this.gridScale*0.5;
      this.laneWidth = this.gridScale*1;
      setTimeout(() => {
        this.readyToEdit = true;
        this.$nextTick(function () {
          //@ts-ignore ToDo: fix type issue
          this.$refs.nsInput.focus();
        });
      }, 600);
    }
    this.$events.fire('northstar-input-in-gantt', true)

  }

  reset() {
    this.currentlyAddingItem = false;
    this.readyToEdit = false;
    this.nsName = null;
    this.laneWidth = null;
    this.xPosition = null;
    this.automaticAdding = false;
    this.setNextColor();
    this.$store.commit('set_gantt_input_text', '');
    this.$store.commit('set_gantt_item_color', '');
  }


  updateNorthstarName() {
    this.createOrAddNorthStar();
    this.$events.fire('northstar-input-in-gantt', false)
    this.reset();
  }

  setNextColor() {
    const northStarCount = this.$store.state.board?.northStars.length || 0;

    if (northStarCount <= 7) {
      this.selectedColor = this.preSetColors[northStarCount % this.preSetColors.length];
    } else {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      this.selectedColor = this.colors[randomIndex];
    }
  }

  handleInputChange() {
    this.$store.commit('set_gantt_input_text', this.nsName || '');
  }
 
  get calculateStartDate() {
    const currentCell = Math.trunc(this.xPosition / this.gridScale);
    const month = this.gridCells[currentCell];

    // Calculate pixel offset and corresponding day in the month
    const dayRepresentation = Math.trunc((this.xPosition % this.gridScale) / this.gridScaleGranularity);
    const maxDueDateOfMonth = getDaysInMonth(new Date(month));

    // Determine the actual day, capping it at the max days in the month
    const realMaxDate = Math.min(dayRepresentation, maxDueDateOfMonth);

    return addDays(new Date(month), realMaxDate);
  }

  get calculateEndDate() {
    // Determine the current cell based on xPosition and laneWidth
    const currentCell = Math.ceil(Math.max(0, (this.xPosition + this.laneWidth) / this.gridScale) - 1);
    const month = this.gridCells[currentCell];

    // Calculate the end position and determine the day within the current cell
    let concentratePixelsToCurrentCell = (this.xPosition + this.laneWidth) % this.gridScale;
    if (concentratePixelsToCurrentCell === 0) {
      concentratePixelsToCurrentCell = this.gridScale;
    }

    const dayRepresentation = Math.trunc(concentratePixelsToCurrentCell / this.gridScaleGranularity);
    const maxDueDateOfMonth = getDaysInMonth(new Date(month));

    // Determine the real date within the month
    const realMaxDate = Math.min(dayRepresentation, maxDueDateOfMonth);
    return setDate(new Date(month), realMaxDate);
  }



  mouseOver(event: MouseEvent) {
    
    if (this.currentlyAddingItem && !this.readyToEdit && !this.automaticAdding) {
      const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
      const currentXposition = event.clientX - rect.left;

      this.laneWidth = Math.trunc(
        Math.round(currentXposition / this.gridScaleGranularity) * this.gridScaleGranularity - this.xPosition
      );
    }
  }

  mouseUp(event: MouseEvent) {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    const x = event.clientX - rect.left;

    this.laneWidth = Math.round((x - this.xPosition) / this.gridScaleGranularity) * this.gridScaleGranularity;

    if(this.currentlyAddingItem) {
      this.$store.commit('set_gantt_item_color', this.selectedColor);
    }
    this.readyToEdit = true;


    if(Math.abs((this.xPosition - x)) < this.gridScaleGranularity*4) {
      this.laneWidth = this.gridScale*1;
      this.automaticAdding = true;
      setTimeout(() => { this.automaticAdding = false; }, 1200);
    }

    this.$nextTick(() => {
      //@ts-ignore ToDo: fix type issue
      this.$refs.nsInput.focus();
    });
  }

  handleBlur() {
    if (!this.nsName) {
      this.reset();
    } else {
      this.updateNorthstarName();
    }
  }

  handleClick(event: MouseEvent) {
    this.reset();
    this.currentlyAddingItem = true;
    
    

    
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();

    // Calculate the X position rounded to the nearest grid scale granularity
    const x = Math.round((event.clientX - rect.left) / this.gridScaleGranularity) * this.gridScaleGranularity;
    const offsetMonthCount = Math.trunc(x / this.gridScale);
    const xWithCellOffset = Math.abs(offsetMonthCount * this.gridScale - x);

    // Set the X position based on the cell offset
    this.xPosition = xWithCellOffset <= (this.gridScaleGranularity * 3)
      ? offsetMonthCount * this.gridScale
      : x;
  }


  createOrAddNorthStar(): void {
    this.$gtag.event('pmb_create_ns');
    this.playPulseAnimation = false;
    if(!this.nsName) {
      this.nsName = "Untitled"
    }
    if (this.$store.state.board) {
      this.service?.createNorthStar(this.$store.state.workspace, this.$store.state.board, this.nsName!, this.calculateEndDate, this.calculateStartDate, this.selectedColor).then((result: any) => {
        this.$emit('completed', true)
        this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
        this.$store.commit('set_newly_created_northstar_in_board', result.data.createNorthStar.id);
        this.$gtag.event('pembio_create_north_star');
        this.reset();
        this.$events.fire('new_northstar_created', result.data.createNorthStar)
       
      })
    } else if(this.$store.state.workspace && !this.$store.state.board) {
      this.service?.createNorthStarWithoutBoard(this.$store.state.workspace, this.nsName!, this.calculateEndDate, this.calculateStartDate, this.selectedColor).then((result: any) => {
        this.$emit('completed', true)
        this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
        this.$events.fire('new_northstar_created', result.data.createNorthStar)
        this.$gtag.event('pembio_create_north_star');
      })
    }
    this.$emit('northstar-added', true);


    this.$store.commit('set_gantt_item_color', '');
  
 
 
  }

  mounted() {
    this.service = new NorthStarService(this.$apollo);
    this.setNextColor();


   
 
    this.$events.listen('trigger_add_northstar', (_) => {
      this.animateAddItem(); 
    })

  

    this.$events.listen('pulse_first_ns_input', (_) => {
      this.playPulseAnimation = true;

      setTimeout(() => {
        this.playPulseAnimation = false;
      }, 15000);
    })
  }

}
