









































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {MyWorkspaceNorthstars, UpdateNorthstarOrder} from "@/graphql/NorthStar";
import {NorthStarPaginator} from "@/typescript/types";
import NorthStarData from "@/components/northstars/NorthStarData.vue";
import SmartDateTime from "@/components/general/SmartDateTime.vue";
import NorthstarObjectiveLists from "@/components/objectives/NorthstarObjectiveLists.vue";
import AddOrLinkNorthStar from "@/components/northstars/AddOrLinkNorthStar.vue";
import {isEqual} from "date-fns";
import AddGoalsAndActivites from "@/components/general/AddGoalsAndActivites.vue";
import OnboardingAddGoal from "@/components/general/OnboardingAddGoal.vue";
import Gantt from "@/components/MasterDashboardWidgets/Gantt.vue";
import {Container, Draggable} from "vue-smooth-dnd";
import sortBy from "lodash/sortBy";
import InRoomActivation from '../auth/register/InRoomActivation.vue';
import NorthstarsGantt from "@/components/gantt/NorthstarsGantt.vue";
import Test from "@/components/gantt/GanttContainer.vue";
import CreateExampleData from "@/components/board/objective/CreateExampleData.vue";
import NorthstarListsSkeleton from "./NorthstarListsSkeleton.vue";
import { compareAsc, parseISO } from 'date-fns';
import CreateNorthstarInline from "../board/objective/CreateNorthstarInline.vue";

@Component({
  components: {
    Test, NorthstarsGantt, Gantt, NorthstarObjectiveLists, SmartDateTime, Container, Draggable, CreateExampleData, NorthstarListsSkeleton , CreateNorthstarInline},
})
export default class MyNorthstars extends Vue {
  mynorthstars: NorthStarPaginator | null = null;
  viewChangeLoader: boolean = false;
  beingDragged: boolean = false;

  placeHolderColors: Array<string> = [
    '#754373',
    '#4a677d',
    '#7b7343',
  ];

  @Prop({default: 'all', required: false})
  selectedBoardId!: string;

  @Prop({default: 'all', required: false})
  selectedStatus!: string;

  @Prop({default: 'all', required: false})
  selectedUser!: string

  @Prop({default: null, required: false})
  startDate!: Date;

  @Prop({default: null, required: false})
  endDate!: Date;

  @Prop({default: false, required: false})
  masonry!: boolean;

  @Prop({default: true, required: false})
  openAddObjective!: boolean;

  @Prop({default: false, required: false})
  isCreateGoalOpen!: boolean;

  page: number = 1;
  sortByDateType: string = this.$store.state.objective_sort_type;
 

 

  get isInGridView() {
    return this.$store.state.dashboard_view === 'grid' && !this.board;
  }

  get workspace(){
    return this.$store.state.workspace;
  }

  get board(){
    return this.$store.state.board;
  }

  get hasMorePages() {
    if(this.mynorthstars && this.mynorthstars.paginatorInfo) {
      return this.mynorthstars.paginatorInfo.hasMorePages;
    }
    return false;
  }

  get myBoards() {
    return this.workspace.boards.filter((board: any) => {
      return board.members.filter((user: any) => {
        return user.id === this.$store.state.me.id;
      })
    })
  }

  get templateOnboarding() {
    return this.$store.state.template_onboarding;
  }

  get filteredNorthstarList() {
    if(this.mynorthstars && this.mynorthstars!.data.length > 0) {
      if(this.selectedBoardId != 'all') {
        const ns = this.mynorthstars!.data.filter((ns: any) => {
          if(ns.status != 'completed') {
            if(!this.selectedBoardId && ns.is_public === true) {
              return ns;
            }else if(new Date(ns.start_date) <= new Date(this.$props.endDate)) {
              return ns;
            }
          }

        });
       
        if(this.workspace.id && this.$props.selectedBoardId != 'all') {
          return sortBy(ns, ['b_order'])
        }
        return sortBy(ns, ['ws_order']);
      }else{
        const ns = this.mynorthstars!.data.filter((ns: any) => {
          if(ns.status != 'completed' && ns.is_public === true) {
            if(new Date(ns.start_date) <= new Date(this.$props.endDate)) {
              return ns;
            }
          }

        });
      
        if(this.workspace.id && this.$props.selectedBoardId != 'all') {
          return sortBy(ns, ['b_order'])
        }
        return sortBy(ns, ['ws_order']);
      }

    }
    return [];

  }


  get isFilterUsed(){
    if(this.$props.startDate != null || this.$props.endDate != null || this.$props.selectedStatus != 'all' || this.$props.selectedBoardId != 'all') {
      return true;
    }
    return false;
  }

  get closestThird() {
    const number = this.filteredNorthstarList.length;
    const multipleOfThree = Math.ceil(number / 3) * 3;
    return multipleOfThree - number;
  }

  get newNorthstarId() {
    return this.$store.state.newly_created_northstar_in_board;
  }

  get userType() {
    return this.$store.state.me.account_type;
  }

  get onboardingStep() {
    return this.$store.state.user_onboarding_number;
  }

  get ganttInputText() {
    return this.$store.state.gantt_input_text;
  }
  
  get ganttItemColor() {
    return this.$store.state.gantt_item_color;
  }

  addNorthstar() {
    this.$buefy.modal.open({
      component: AddOrLinkNorthStar,
      props: {
        origin: (this.$store.state.board) ? 'board' : 'workspace',
        inModal: true,
        startDate: this.$props.startDate
      },
      width: '480px',
      // @ts-ignore
      parent: this,
    });
  }

  getMyNorthstars() {
    this.$apollo.query({
      query: MyWorkspaceNorthstars,
      fetchPolicy: 'no-cache',
      variables: {
        workspace_id: this.workspace.id,
        board_id: this.$props.selectedBoardId,
        user_id: this.$props.selectedUser,
        status: this.$props.selectedStatus,
        start_date: this.$props.startDate,
        end_date: this.$props.endDate,
        page: this.page,
        sortByDateType: 'start_date'
      },

    }).then((result: any) => {
      if(this.mynorthstars && this.mynorthstars.data.length > 0) {
        this.mynorthstars.data = this.filterDuplicateData([...this.mynorthstars.data, ...result.data.myNorthstarsInWorkspace.data]);
        this.mynorthstars.paginatorInfo = result.data.myNorthstarsInWorkspace.paginatorInfo;
      }else{
       
        this.mynorthstars = result.data.myNorthstarsInWorkspace;
      }
    }).catch((error: any) =>{
    
      Vue.prototype.$consoleLog(error)
    })
  }

  openAddNorthStar() {
    this.$buefy.modal.open({
      component: AddGoalsAndActivites,
      //@ts-ignore
      customClass: 'add_goal_from_template',
      parent: this,
    });
  }

 

  openOnboardingAddGoal() {
    this.$buefy.modal.open({
      component: OnboardingAddGoal,
      //@ts-ignore
      parent: this,
      customClass: 'add_goal_from_onboarding',
    });
  }

  openGoalsAndActivites() {
    this.$buefy.modal.open({
      component: AddGoalsAndActivites,
      //@ts-ignore
      parent: this,
      customClass: 'add_goal_from_onboarding',
    });
  }

  loadMore() {
    this.page++;
    this.$events.fire('load-mynorthstar-pages', this.page);
    this.getMyNorthstars();
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }


  openNorthStarAsModal(id: string) {
    this.$buefy.modal.open({
      component: NorthStarData,
      props: {
        onmodal: true,
        editable: true,
        showMilestones: true,
        //@ts-ignore
        board: (this.$store.state.board) ? this.$store.state.board : this.$props.board,
        id: id
      },
      width: '780px',
      animation: 'none',
      customClass: 'northstar-detailed is-paddingless',
      // @ts-ignore
      parent: this,
    });
  }

  // openDateRangePicker()
  // {
  //   this.$buefy.modal.open({
  //     component: SmartDateTime,
  //     width: '280px',
  //     // @ts-ignore
  //     customClass: 'is-paddingless top-right',
  //     parent: this,
  //     props: {
  //       range: true
  //     },
  //     events: {
  //       //@ts-ignore ToDo: fix type issue
  //       'dates': value => {
  //
  //         this.start_date = value.date1;
  //         this.end_date = value.date2;
  //         this.mynorthstars = null;
  //         this.getMyNorthstars();
  //
  //       }
  //     },
  //   })
  // }

  clearFilter() {
    this.$props.selectedBoardId = 'all';
    this.$props.selectedStatus = 'all';
    this.$props.startDate = null;
    this.$props.endDate = null;
    this.mynorthstars = null;
    this.getMyNorthstars();
  }

  reorderList() {
    if (this.mynorthstars && this.mynorthstars.data && this.mynorthstars.data.length > 0) {
      this.mynorthstars.data.sort((a, b) => {
        // Use date-fns to compare dates
        const dateComparison = compareAsc(parseISO(a.start_date), parseISO(b.start_date));
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return 0; // Return 0 if the dates are equal
      });
    
    }

  }

  mounted() {

    this.getMyNorthstars();
    this.$events.listen('northstar-color-change', eventData => {
      this.mynorthstars!.data.map((ns: any) => {
        if(ns.id == eventData.northstar_id) {
          return ns.color = eventData.color;
        }
      })
    })

    this.$events.listen('northstars-updated-my-northstar', eventData => {

      let index = this.mynorthstars!.data.findIndex((ns: any) => ns.id === eventData.id);

      if(index == -1) {
        eventData['objectives'] = {
          data: [],
          paginatorInfo: {
            total: 0,
            currentPage: 1,
            hasMorePages: false
          }
        }
        this.mynorthstars!.data.push(eventData);
      }else{
        this.mynorthstars!.data.map((ns: any) => {
          if(ns.id == eventData.id) {
            ns.description = eventData.description;
            ns.status = eventData.status;
            ns.due_date = eventData.due_date;
            ns.start_date = eventData.start_date;
            ns.name = eventData.name;
            ns.is_public = eventData.is_public;


            return ns;
          }

        })
       
      }
      this.reorderList()
      
    })

   

    // this.$events.listen('reorder-list', () => {
    //   // Sort the mynorthstars.data by due_date, using date-fns for date comparison
    //   if (this.mynorthstars && this.mynorthstars.data && this.mynorthstars.data.length > 0) {
    //     this.mynorthstars.data.sort((a, b) => {
    //       // Use date-fns to compare dates
    //       const dateComparison = compareAsc(parseISO(a.due_date), parseISO(b.due_date));
    //       if (dateComparison !== 0) {
    //         return dateComparison;
    //       }
    //     });

        
    //   }
    // });

    this.$events.listen('new_northstar_created', eventData => {
      eventData['objectives'] = {
        data: [],
        paginatorInfo: {
          total: 0,
          currentPage: 1,
          hasMorePages: false
        }
      };

      // Find the correct insertion point in the underlying data array (mynorthstars.data)
      const index = this.mynorthstars?.data.findIndex(ns => {
        return eventData.start_date < ns.start_date;  
      });

      if (index === -1 || index === undefined) {
    // If it should be the last item, push it to the end
    this.mynorthstars?.data.push(eventData);
     
      } else {
    // Otherwise, insert it into the correct position
    this.mynorthstars?.data.splice(index, 0, eventData);
      }

      // this.addAGoalOpen = false;

      
    });

    this.$events.listen('northstar_board_selected', eventData => {
      let index = this.mynorthstars!.data.findIndex((ns: any) => ns.id === eventData.id);
      if(index != -1) {
        this.mynorthstars!.data[index] = eventData;
      }

    })

    this.$events.listen('north-star-delete', (eventData) => {
      let index = this.mynorthstars!.data.findIndex((ns: any) => ns.id === eventData);

      if(index != -1) {
        this.mynorthstars!.data.splice(index, 1);
      }
    })

    this.$events.listen('sort_by_date_master_dashboard', eventData => {
      
      this.sortByDateType = eventData;
    })
  }


  /**
                  * Drag and Drop starts
                  * */

  onDragStart(_dragResult: any): void {
  
    //this.$events.fire('toggle-goals-on-dnd', {northstarId: _dragResult.payload.id, toggle: false});

  }

  getGhostParentNorthstar() {
    return document.body;
  }

  getChildPayloadNorthstar(index: number) {

    if (this.filteredNorthstarList) {
      //this.$events.fire('toggle-goals-on-dnd', {northstarId: this.filteredNorthstarList[index].id, toggle: false});
      return this.filteredNorthstarList[index]
    }
  }

  onDrop(dropResult: any): void {
    //this.$events.fire('toggle-goals-on-dnd', {northstarId: dropResult.payload.id, toggle: true});
    if (this.filteredNorthstarList) {
      this.applyDrag(dropResult);
    }
  }

  applyDrag(dragResult: any): void {
    let {removedIndex, addedIndex, payload} = dragResult
    if (removedIndex === null && addedIndex === null || !payload) return;


    if (!this.filteredNorthstarList) {
      return;
    }

    if(removedIndex != null && addedIndex != null) {

      if (addedIndex >= this.filteredNorthstarList.length) {
        let k = addedIndex - this.filteredNorthstarList.length + 1;
        while (k--) {
          //@ts-ignore
          this.filteredNorthstarList.push(undefined);
        }
      }
      this.filteredNorthstarList.splice(addedIndex, 0, this.filteredNorthstarList.splice(removedIndex, 1)[0]);

      this.modifyOrder();
    }

  }

  modifyOrder() {
                  this.filteredNorthstarList?.forEach((item: any, i: number) => {
                    if(this.workspace.id && this.$props.selectedBoardId != 'all') {
                      item.b_order = i;
                    }else{
                      item.ws_order = i;
                    }

                  });

                  this.updateNorthstarOrder();


  }

  updateNorthstarOrder() {
    this.$apollo.mutate({
      mutation: UpdateNorthstarOrder,
      variables: {
        orderType: (this.workspace.id && this.$props.selectedBoardId != 'all') ? 'board' : 'workspace',
        northStarIds: this.filteredNorthstarList.map(item => item.id)
      }
    }).then((res) => {
      Vue.prototype.$consoleLog(res)
    })
  }

  openActivationModal() {
    this.$buefy.modal.open({
      component: InRoomActivation,
      props: {
        fromExpansion: true,
      },   
      //@ts-ignore
      customClass: 'add_goal_from_onboarding',
      parent: this,
    });
  }

  @Watch('selectedBoardId')
  onBoardSelectionChanged(newValue: String){
    if(newValue) {
      this.mynorthstars = null;
      this.getMyNorthstars();
    }
  }

  @Watch('selectedStatus')
  onStatusSelectionChange(newValue: String){
    if(newValue) {
      this.mynorthstars = null;
      this.getMyNorthstars();
    }
  }

  @Watch('endDate')
  onStartDateChange(newValue: Date, oldValue: Date){
    if(!isEqual(new Date(newValue),new Date(oldValue))) {
      this.mynorthstars = null;
      this.viewChangeLoader = true;
      this.getMyNorthstars();
      setTimeout(() => this.viewChangeLoader = false, 800);
    }
  }

  @Watch('selectedUser')
  onSelectedUserChange(newValue: String){
    if(newValue) {
      this.mynorthstars = null;
      this.getMyNorthstars();
    }
  }

  @Watch('ganttItemColor')
  onGanttItemColorChange(newVal: string | null) {
    if (newVal) {
      // Use $nextTick to ensure the DOM has updated
      this.$nextTick(() => {
        const ghostElement = this.$refs.ghostItem as HTMLElement
        if (ghostElement) {
          ghostElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    }
  }
 
}
