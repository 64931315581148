





























































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Planner from "@/components/layout/Planner.vue";
import {
  eachMonthOfInterval,
  isWithinInterval,
  addMonths,
  subMonths,
  isSameMonth,
  startOfMonth,
  isBefore,
  isAfter,
  compareAsc, parseISO
} from "date-fns";
import VueDraggableResizable from 'vue-draggable-resizable';
import NorthStarService from "@/NorthStarService";
import {NorthStar, NorthStarPaginator} from "@/typescript/types";
import NorthstarGanttSwimLane from "@/components/gantt/NorthstarGanttSwimLane.vue";
import {MyWorkspaceGanttNorthstars} from "@/graphql/NorthStar";
import AddGanttItem from "@/components/gantt/AddGanttItem.vue";
import CuratedGoalsSelector from "@/components/templates/CuratedGoalsSelector.vue";

@Component({
  components: {AddGanttItem, NorthstarGanttSwimLane, Planner, VueDraggableResizable},
})
export default class GanttContainer extends Vue {
  intervalStart: any = null;
  intervalEnd: any = null;
  periodicity: Array<string> = [
    'weekly', 'monthly', 'quarterly', 'yearly'
  ]
  selectedPeriodicity: string = 'monthly';

  mynorthstars: NorthStarPaginator | null = null;
  showUnscheduled: boolean = false;
  reRenderingItems: boolean = false;
  page: number = 1;
  gridMovementScale: number = 186;

  currentlySelecting: boolean = false;
  selectedStartCell: any = null;
  selectedEndCell: any = null;

  selectedSwimlaneItemId: string | null = null;

  service: NorthStarService | null = null;
  northstar: NorthStar | null = null;
  nsDueDate: any | null = null;
  nsStartDate: any | null = null;
  createNorthStarInput: string | null = 'new goal';
  submitted: boolean = false;
  lastCreatedNorthstarId: string | null = null;
  nsCurrentlyBeingAdded: string | null = null;

  selectedColor: string | null = null;
  colors: Array<string> = [
    '#773f75',
    '#bd5fb4',
    '#374ea9',
    '#776015',
    '#7b7343',
    '#316863',
    '#2e825f',
    '#654C4F',
    '#5B507A',
    '#546356',
    '#1F4730',
    '#642B2C',
    '#2E294E',
    '#820263',
    '#64024C',
  ];

  @Prop({ default: () => new Date(), required: false })
  startDate!: Date;

  @Prop({ default: () => new Date(), required: false })
  dueDate!: Date;


  get workspace() {
    return this.$store.state.workspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get boardId() {
    if(this.$store.state.board) {
      return this.$store.state.board.id;
    } else {
      return null;
    }
  }



  // get onboardingStep() {
  //   return this.$store.state.user_onboarding_number;
  // }


  get onboarding() {
    return this.$store.state.onboarding;
  }

  get filteredNorthstars() {
    if (!this.showUnscheduled) {
      if (!this.mynorthstars) return [];

      return this.mynorthstars.data.filter((northstar: any) => {
        return northstar.start_date !== null || northstar.due_date !== null;
      });
    } else {
      return this.mynorthstars ? this.mynorthstars.data : [];
    }
  }

  get unscheduledNorthstars() {
    if (!this.mynorthstars) return [];

    return this.mynorthstars.data.filter((northstar: any) => {
      return northstar.start_date === null && northstar.due_date === null;
    });
  }

  startCell(date: any) {
    return this.startDate ? isSameMonth(new Date(this.startDate), new Date(date)) : false;
  }

  endCell(date: any) {
    return this.dueDate ? isSameMonth(new Date(this.dueDate), new Date(date)) : false;
  }

  handleUnscheduled() {
    this.showUnscheduled = !this.showUnscheduled;
    this.$gtag.event('unscheduled_button_clicked');
  }

  setSelectedItem(id: string) {
    //unselect if same id
    if(this.selectedSwimlaneItemId === id) {
      this.selectedSwimlaneItemId = null;
      //select new if another item is already selected
    } else {
      this.selectedSwimlaneItemId = id;
    }
  }

  deleteItem(id: string) {
    this.service?.deleteNorthStar(id).then(() => {
      this.$events.fire('north-star-delete', id);
    });
  }

  jumpToMonth(month: string | Date | null) {
    let parsedDate: Date | null = null;
    if (month) {
      parsedDate = typeof month === 'string' ? parseISO(month) : month;
    }
    if (this.activeCell(parsedDate)) {
      this.scrollToMonth();
    } else {
      this.$emit('period-clicked', parsedDate);
    }
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }

  handleAddTemplateGoal() {
    if(this.onboarding) {
      this.$store.commit('set_onboarding', null);
    }
    this.openCuratedGoalsSelector();
  }

  getMyNorthstars() {
    this.$apollo.query({
      query: MyWorkspaceGanttNorthstars,
      fetchPolicy: "no-cache",
      variables: {
        workspace_id: this.workspace.id,
        board_id: this.boardId,
        user_id: 'all',
        status: 'all',
        start_date: new Date(this.intervalStart),
        end_date: new Date(this.intervalEnd),
        sortByDateType: 'start_date',
        page: this.page,
      },
    }).then((result: any) => {

      const fetchedNorthstars = result.data.myNorthstarsInWorkspace.data;

      this.checkAndAdjustIntervalStart(fetchedNorthstars);

      if(this.mynorthstars && this.mynorthstars.data.length > 0) {
        this.mynorthstars.data = this.filterDuplicateData([...this.mynorthstars.data, ...result.data.myNorthstarsInWorkspace.data]);
        this.mynorthstars.paginatorInfo = result.data.myNorthstarsInWorkspace.paginatorInfo;
      }else{
        this.mynorthstars = result.data.myNorthstarsInWorkspace;
      }

    }).catch((error: any) => {
      Vue.prototype.$consoleLog(error);
    });
  }

  reset() {
    this.selectedColor = null;
    this.submitted = false;
    this.selectedStartCell = null;
    this.selectedEndCell = null;
  }

  addNorthstarStatic() {
    this.$events.fire('trigger_add_northstar');
    this.$gtag.event('northstar_initiated_with_gantt_button');
    /*
    this.nsStartDate = new Date(this.startDate);
    this.nsDueDate = new Date(this.dueDate);
    this.createOrAddNorthStar();

     */
  }

  @Prop({required: false, default: null})
  items!: Array<any>

  get gridCells() {
    let result = eachMonthOfInterval({
      start: this.intervalStart,
      end: this.intervalEnd
    })

    return result;
  }

  isWithinSelectionRange(date: any) {
    return isWithinInterval(new Date(date), {
      start: new Date(this.selectedStartCell),
      end: new Date(this.selectedEndCell)
    })
  }

  reRenderSwimlaneItems() {
    this.reRenderingItems = true;
    setTimeout(() => {
      this.reRenderingItems = false;
    }, 600);
  }

  extendIntervalBack() {
    this.intervalStart = subMonths(new Date(this.intervalStart), 1);
    this.getMyNorthstars();
    this.reRenderSwimlaneItems();
  }
  extendIntervalForward() {
    this.intervalEnd = addMonths(new Date(this.intervalEnd), 6);
    this.getMyNorthstars();
    this.reRenderSwimlaneItems();
  }

  scrollToMonth() {
    const areaBox = document.querySelector('.active_cell') as HTMLInputElement;
    if(areaBox) {
      areaBox.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
  }

  activeCell(date: any) {
    return this.startDate ? isSameMonth(new Date(this.startDate), new Date(date)) : false;
  }

  openCuratedGoalsSelector() {
    this.$buefy.modal.open({
      component: CuratedGoalsSelector,
      width: '700px',
      parent: this,
      customClass: 'curated_modal'
    });
  }

  @Watch('startDate', {deep: true})
  startDateChangedFromParent(newVal: Date) {
    let before = isBefore(new Date(newVal), new Date(this.intervalStart));
    let after = isAfter(new Date(newVal), new Date(this.intervalEnd));

    if(before) {
      this.extendIntervalBack();
    } else if (after) {
      this.extendIntervalForward();
    }
    this.$nextTick(function () {
      this.scrollToMonth();
    });
  }

  

  @Watch('$route', { immediate: true, deep: true })
  onRouteChanged(to: any, from: any){

    if(to?.path != from?.path) {
      this.page = 1;
      this.mynorthstars = null;
      this.getMyNorthstars();
    }
  }

  setInitialInterval() {
    // WARNING: DATE FOR START/END MUST START ON FIRST DAY OF MONTH
    let date = startOfMonth(new Date());
    let start = date;
    let end = startOfMonth(new Date(date.setMonth(date.getMonth() + 12)));

    if(this.startDate) {
      start = startOfMonth(this.startDate)
    }
    this.intervalStart = start;
    this.intervalEnd = end;
    this.getMyNorthstars();
  }

  checkAndAdjustIntervalStart(northstars: any[]) {
    if (!northstars.length) return;

    let earliestStartDate: Date | null = null;

    northstars.forEach((northstar) => {
      if (northstar.start_date) {
        const startDate = new Date(northstar.start_date);
        if (!isNaN(startDate.getTime()) && startDate < new Date(this.intervalStart)) {
          if (earliestStartDate === null || startDate < earliestStartDate) {
            earliestStartDate = startDate;
          }
        }
      }
    });

    if (earliestStartDate) {
      this.intervalStart = startOfMonth(earliestStartDate);
      this.$nextTick(function () {
        this.scrollToMonth();
      });
    }
  }

  reorderList() {
    if (this.mynorthstars && this.mynorthstars.data && this.mynorthstars.data.length > 0) {
      this.mynorthstars.data.sort((a, b) => {
        // Use date-fns to compare dates
        const dateComparison = compareAsc(parseISO(a.start_date), parseISO(b.start_date));
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return 0; // Return 0 if the dates are equal
      });
      this.mynorthstars.data = this.filterDuplicateData(this.mynorthstars.data);
    }
  }

  mounted() {
    this.service = new NorthStarService(this.$apollo);

    this.$events.listen('new_northstar_created', (northstar) => {
      this.lastCreatedNorthstarId = northstar.id;
    });

    this.$events.listen('new_northstar_created', eventData => {
      eventData['objectives'] = {
        data: [],
        paginatorInfo: {
          total: 0,
          currentPage: 1,
          hasMorePages: false
        }
      };

      // Find the correct insertion point in the underlying data array (mynorthstars.data)
      const index = this.mynorthstars?.data.findIndex(ns => {
        return eventData.start_date < ns.start_date;
      });

      if (index === -1 || index === undefined) {
        // If it should be the last item, push it to the end
        //this.mynorthstars?.data.push(eventData);
        if(this.mynorthstars) {
          this.mynorthstars.data =  this.filterDuplicateData([...this.mynorthstars.data, eventData])
        }
      } else {
        // Otherwise, insert it into the correct position
        this.mynorthstars?.data.splice(index, 0, eventData);
      }


    });
    this.$events.listen('north-star-delete', (eventData) => {
      let index = this.mynorthstars!.data.findIndex((ns: any) => ns.id === eventData);

      if(index != -1) {
        this.mynorthstars!.data.splice(index, 1);
      }
    })
    this.$events.listen('northstars-updated-my-northstar', eventData => {
      if(eventData.start_date && isBefore(new Date(eventData.start_date), new Date(this.intervalStart))) {
        this.intervalStart = startOfMonth(new Date(eventData.start_date));
        this.reRenderSwimlaneItems();
      }

      let index = this.mynorthstars!.data.findIndex((ns: any) => ns.id === eventData.id);

      if(index == -1) {
        eventData['objectives'] = {
          data: [],
          paginatorInfo: {
            total: 0,
            currentPage: 1,
            hasMorePages: false
          }
        }
        this.mynorthstars!.data.push(eventData);
      }else{
        this.mynorthstars!.data.map((ns: any) => {
          if(ns.id == eventData.id) {
            ns.description = eventData.description;
            ns.status = eventData.status;
            ns.due_date = eventData.due_date;
            ns.start_date = eventData.start_date;
            ns.name = eventData.name;
            ns.is_public = eventData.is_public;


            return ns;
          }

        })

      }
      this.reorderList()

    })
    this.$events.listen('northstar-color-change', (eventData) => {
      this.mynorthstars!.data.map((ns: any) => {
        if(ns.id == eventData.northstar_id) {
          return ns.color = eventData.color;
        }
      })
    });
    this.$events.listen('load-mynorthstar-pages', (eventData) => {
      if(eventData) {
        this.page = eventData;
        this.getMyNorthstars()
      }
    })

   
    this.setInitialInterval();
  }
}
